<!--
 * @Descripttion:  网约车审批列表内容||批量审批列表内容
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-09-08 15:48:19
-->
<template>
  <div class="approve_list_con allOrderBox" @click="toDetailFun(item)">
    <div class="approve_list_con_left">
    <div class="inForBox">
      <div>
        <p class="iconBox">
          <svg class="icon" aria-hidden="true" v-if="item.sourceIcon || item.SourceIcon">
            <use :xlink:href="'#' + (item.sourceIcon || item.SourceIcon)" />
          </svg>
          <img src="../../../assets/images/defaultIcon.png" v-else />
        </p>
        <template v-if="item.supplierName || item.SourceCodeTxt">
          <p>{{ item.supplierName || item.SourceCodeTxt }}</p>
          <a v-if="item.CarLevel">{{ item.CarLevel }}</a>
          <a v-if="item.TypeTxt && item.TypeTxt != 'null' && item.TypeTxt != item.CarLevel">{{ item.TypeTxt }}</a>
          <a v-if="item.typeTxt">{{ item.typeTxt }}</a>
          <a v-if="item.TypeTxt != item.Rule">{{ item.Rule }}</a>
          <a class="channelTxt" v-if="item.channelTxt">{{ item.channelTxt }}</a>
        </template>
        <template v-else>
          <p>请填写用车需求</p>
        </template>
      </div>

      <div>
        <eventTag :statusTxt="item.statusTxt || item.ExtOrderStatusTxt" :status="item.status || item.Status"></eventTag>
      </div>
    </div>
    <p v-if="item.departureTime || item.ItemData.orderTime || (item.Departure_time && item.Departure_time != 'null')" class="departure_time">
      <svg class="icon timeIcon" aria-hidden="true">
        <use xlink:href="#icon-shijian" />
      </svg>
      <template v-if="item.ItemData && item.ItemData.carRule == '14' && item.ItemData.orderTime">{{ item.ItemData.orderTime }}</template>
      <template v-if="item.Departure_time && item.Departure_time != 'null'">
        <template v-if="item.Departure_time.indexOf('T') > -1">{{ item.Departure_time | formatDate('yyyy-MM-dd hh:mm') }}</template>
        <template v-else>{{ item.Departure_time }}</template>
      </template>
      {{ item.departureTime | formatDate('yyyy-MM-dd hh:mm') }}
    </p>
    <template v-if="item.endName || item.Addr_end">
      <p>
        <i class="greenBc"></i>
        {{ item.startName || item.Addr_start }}
      </p>
      <p>
        <i class="orangeBc"></i>
        {{ item.endName || item.Addr_end }}
      </p>
    </template>
    <template v-if="item.carLevel">
      <p class="car_level">
        {{ item.carLevel }}
      </p>
    </template>
    <p class="shortp" v-if="item.passengerName">订单创建人 {{ item.passengerName }}</p>
    <p class="shortp" v-if="item.channel != 2 && $cookies.get('tenant') == 'msd'">EasyCarNO:{{ item.orderExtNum || item.ExtOrderNum || '暂无' }}</p>
    <div v-if="!item.ItsButtons" class="callbox" @click.stop="showCallBox(item)">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icondianhua-" />
      </svg>
    </div>
  </div>
    <div class="approve_list_con_right">
      <span @click.stop>
      <Checkbox :name="item.TaskId" :key="item.TaskId" shape="square" checked-color="var(--themeColor)" />
    </span>
    </div>
  </div>
</template>

<script>
import { Checkbox } from 'vant';
export default {
  props: ['item'],
  components: {
    Checkbox
  },methods: {
    toDetailFun(item) {
      this.SmartStorage.set('orderDetail', item);
      this.$router.push({
        path: '/platformPriceCoupon'
      });
    }
    
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/msd/allCarOrder.scss';
.approve_list_con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .icon {
    width: 0.15rem;
  }
  .shortp {
    display: flex;
    width: 100%;
    align-items: flex-start;
    flex-direction: column;
  }
  &_left {
    p,
    span {
      text-align: left;
      font-size: 0.13rem;
      color: $txt_color;
    }
  }
  &_right {
    margin-left: 0.2rem;
  }
}
/deep/.van-cell {
  padding: 0 !important;
}
/deep/.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}
/deep/.van-checkbox-group {
  width: 100%;
}
</style>
